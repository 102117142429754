<template>
  <v-container>
    <div class="ml-2"><PageTitle text="Reports" /></div>
  </v-container>
</template>

<script>
import PageTitle from "../../components/PageTitle";
export default {
  name: "Reports",
  components: { PageTitle }
};
</script>

<style scoped></style>
